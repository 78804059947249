@charset "UTF-8";
.footer {
  box-sizing: border-box;
  line-height: 1.6;
  letter-spacing: 0.05em;
}

.footer html, .footer body, .footer div, .footer span, .footer applet, .footer object, .footer iframe, .footer h1, .footer h2, .footer h3, .footer h4, .footer h5, .footer h6, .footer p, .footer blockquote, .footer pre, .footer a, .footer abbr, .footer acronym, .footer address, .footer big, .footer cite, .footer code, .footer del, .footer dfn, .footer em, .footer img, .footer ins, .footer kbd, .footer q, .footer s, .footer samp, .footer small, .footer strike, .footer strong, .footer sub, .footer sup, .footer tt, .footer var, .footer b, .footer u, .footer i, .footer center, .footer dl, .footer dt, .footer dd, .footer ol, .footer ul, .footer li, .footer fieldset, .footer form, .footer label, .footer legend, .footer table, .footer caption, .footer tbody, .footer tfoot, .footer thead, .footer tr, .footer th, .footer td, .footer article, .footer aside, .footer canvas, .footer details, .footer embed, .footer figure, .footer figcaption, .footer footer, .footer header, .footer hgroup, .footer menu, .footer nav, .footer output, .footer ruby, .footer section, .footer summary, .footer time, .footer mark, .footer audio, .footer video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.footer article, .footer aside, .footer details, .footer figcaption, .footer figure, .footer footer, .footer header, .footer hgroup, .footer menu, .footer nav, .footer section {
  display: block;
}

.footer body {
  line-height: 1;
}

.footer ol, .footer ul {
  list-style: none;
}

.footer blockquote, .footer q {
  quotes: none;
}

.footer blockquote::before, .footer blockquote::after, .footer q::before, .footer q::after {
  content: '';
  content: none;
}

.footer table {
  border-collapse: collapse;
  border-spacing: 0;
}

.footer input, .footer select, .footer button, .footer textarea {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  border-radius: 0;
  outline: none;
  appearance: none;
}

.footer select::-ms-expand {
  display: none;
}

.footer *, .footer *::before, .footer *::after {
  box-sizing: border-box;
}

.footer a {
  color: #333333;
  text-decoration: none;
  overflow-wrap: break-word;
  transition: 0.3s ease-out;
}

.footer a:visited {
  color: #333333;
}

.footer a:hover {
  color: #333333;
}

.footer a:active {
  color: #333333;
}

.footer a[href*="tel:"] {
  text-decoration: none;
  pointer-events: none;
  cursor: default;
}

.footer img {
  display: block;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

.footer img:not(.no-fit) {
  width: 100%;
}

.footer .list__item {
  margin-left: 1em;
  text-indent: -1em;
}

.footer .list__item:not(:nth-last-of-type(1)) {
  margin-bottom: 4px;
}

.footer .list__item::before {
  content: '・';
}

.footer .mb-x1 {
  margin-bottom: 6px;
}

@media screen and (max-width: 520px) {
  .footer .mb-x1 {
    margin-bottom: 4px;
  }
}

.footer .mb-x2 {
  margin-bottom: 12px;
}

@media screen and (max-width: 520px) {
  .footer .mb-x2 {
    margin-bottom: 8px;
  }
}

.footer .mb-x3 {
  margin-bottom: 18px;
}

@media screen and (max-width: 520px) {
  .footer .mb-x3 {
    margin-bottom: 12px;
  }
}

.footer .mb-x4 {
  margin-bottom: 24px;
}

@media screen and (max-width: 520px) {
  .footer .mb-x4 {
    margin-bottom: 16px;
  }
}

.footer .mb-x5 {
  margin-bottom: 30px;
}

@media screen and (max-width: 520px) {
  .footer .mb-x5 {
    margin-bottom: 20px;
  }
}

.footer .mb-x6 {
  margin-bottom: 36px;
}

@media screen and (max-width: 520px) {
  .footer .mb-x6 {
    margin-bottom: 24px;
  }
}

.footer .mb-x7 {
  margin-bottom: 42px;
}

@media screen and (max-width: 520px) {
  .footer .mb-x7 {
    margin-bottom: 28px;
  }
}

.footer .mb-x8 {
  margin-bottom: 48px;
}

@media screen and (max-width: 520px) {
  .footer .mb-x8 {
    margin-bottom: 32px;
  }
}

.footer .mb-x9 {
  margin-bottom: 54px;
}

@media screen and (max-width: 520px) {
  .footer .mb-x9 {
    margin-bottom: 36px;
  }
}

.footer .mb-x10 {
  margin-bottom: 60px;
}

@media screen and (max-width: 520px) {
  .footer .mb-x10 {
    margin-bottom: 40px;
  }
}

.footer .mb-x11 {
  margin-bottom: 66px;
}

@media screen and (max-width: 520px) {
  .footer .mb-x11 {
    margin-bottom: 44px;
  }
}

.footer .mb-x12 {
  margin-bottom: 72px;
}

@media screen and (max-width: 520px) {
  .footer .mb-x12 {
    margin-bottom: 48px;
  }
}

.footer .mb-x13 {
  margin-bottom: 78px;
}

@media screen and (max-width: 520px) {
  .footer .mb-x13 {
    margin-bottom: 52px;
  }
}

.footer .mb-x14 {
  margin-bottom: 84px;
}

@media screen and (max-width: 520px) {
  .footer .mb-x14 {
    margin-bottom: 56px;
  }
}

.footer .mb-x15 {
  margin-bottom: 90px;
}

@media screen and (max-width: 520px) {
  .footer .mb-x15 {
    margin-bottom: 60px;
  }
}

.footer .mb-x16 {
  margin-bottom: 96px;
}

@media screen and (max-width: 520px) {
  .footer .mb-x16 {
    margin-bottom: 64px;
  }
}

.footer .mb-x17 {
  margin-bottom: 102px;
}

@media screen and (max-width: 520px) {
  .footer .mb-x17 {
    margin-bottom: 68px;
  }
}

.footer .mb-x18 {
  margin-bottom: 108px;
}

@media screen and (max-width: 520px) {
  .footer .mb-x18 {
    margin-bottom: 72px;
  }
}

.footer .mb-x19 {
  margin-bottom: 114px;
}

@media screen and (max-width: 520px) {
  .footer .mb-x19 {
    margin-bottom: 76px;
  }
}

.footer .mb-x20 {
  margin-bottom: 120px;
}

@media screen and (max-width: 520px) {
  .footer .mb-x20 {
    margin-bottom: 80px;
  }
}

.footer {
  position: sticky;
  top: 100vh;
  width: 100%;
  width: 100%;
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 40px;
  padding-left: 40px;
  padding: 40px 32px;
  background: rgba(244, 233, 197, 0.2);
}

@media screen and (max-width: 1280px) {
  .footer {
    padding-right: 32px;
    padding-left: 32px;
  }
}

@media screen and (max-width: 960px) {
  .footer {
    padding-right: 24px;
    padding-left: 24px;
  }
}

@media screen and (max-width: 520px) {
  .footer {
    padding-right: 16px;
    padding-left: 16px;
  }
}

@media screen and (max-width: 520px) {
  .footer {
    padding: 20px 16px;
  }
}

.footer .footer-info {
  padding: 24px;
  background: #ffffff;
  border-radius: 2px;
}

@media screen and (max-width: 520px) {
  .footer .footer-info {
    padding: 12px;
  }
}

.footer .footer-info .footer-info-unit {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.footer .footer-info .footer-info-unit__col {
  width: 100%;
}

.footer .footer-info .footer-info-sect:not(:nth-last-of-type(1)) {
  margin-bottom: 16px;
}

.footer .footer-info .footer-info-ttl {
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: solid 3px #f4e9c5;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.4;
}

.footer .footer-info .footer-info-subttl {
  position: relative;
  margin-bottom: 8px;
  font-weight: bold;
}

.footer .footer-info .footer-info-subttl::before {
  content: '●';
}

.footer .footer-info .footer-info-subttl span {
  font-size: 14px;
}

.footer .footer-info .footer-info-link a {
  color: #73B41B;
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
}

.footer .footer-info .footer-info-link a:hover {
  text-decoration: none;
}

.footer .footer-info .footer-info-payment {
  max-width: 380px;
}

.footer .footer-info .footer-info-credit {
  max-width: 252px;
}

.footer .footer-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 24px;
}

.footer .footer-nav__item {
  position: relative;
  padding: 0 8px;
  font-size: 12px;
  font-weight: bold;
}

.footer .footer-nav__item:not(:nth-last-of-type(1))::after {
  content: '';
  position: absolute;
  top: 5px;
  right: 0;
  width: 1px;
  height: 12px;
  background: #333333;
}

.footer .footer-nav__item a:hover {
  color: #73B41B;
  text-decoration: underline;
}

.footer .copy {
  padding-bottom: 40px;
  font-size: 10px;
  text-align: center;
}

@media screen and (max-width: 520px) {
  .footer .copy {
    padding-bottom: 48px;
  }
}

.float-foot {
  box-sizing: border-box;
  position: absolute;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  height: 64px;
  padding: 0 8px;
  background: #ffffff;
}

.float-foot__item {
  width: calc(50% - 32px);
  padding: 8px 0;
}

.float-foot__item a {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 100%;
  background: #30669B;
  border-radius: 2px;
  color: #ffffff;
  font-size: 14px;
  text-decoration: none;
}

@media screen and (max-width: 520px) {
  .float-foot__item a {
    gap: 4px;
    font-size: 12px;
  }
}

.float-foot__item a .ico-tel {
  width: 24px;
  height: 16px;
  fill: #ffffff;
}

@media screen and (max-width: 520px) {
  .float-foot__item a .ico-tel {
    width: 20px;
    height: 14px;
  }
}

.drawer {
  box-sizing: border-box;
  display: block;
  position: relative;
  top: 0;
  right: 0;
  z-index: 9999;
}

.drawer .drawer-switch {
  display: none;
}

.drawer-hamburger {
  display: block;
  position: absolute;
  position: fixed;
  right: 8px;
  bottom: 8px;
  z-index: 9998;
  width: 48px;
  height: 48px;
  background: #30669B;
  border-radius: 2px;
  transition: 0.3s ease-out;
  cursor: pointer;
}

.drawer-hamburger span {
  display: block;
  position: relative;
  top: 50%;
  width: 16px;
  height: 2px;
  margin: 0 auto;
  transform: translateY(-50%);
}

.drawer-hamburger span::before, .drawer-hamburger span::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background: #ffffff;
  transition: 0.3s ease-in-out;
}

.drawer-hamburger span::before {
  top: -4px;
}

.drawer-hamburger span::after {
  top: 4px;
}

.drawer-switch:checked ~ .drawer-hamburger span::before {
  top: 0;
  transform: rotate(45deg);
}

.drawer-switch:checked ~ .drawer-hamburger span::after {
  top: 0;
  transform: rotate(-45deg);
}

.drawer-overlay {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9997;
  width: 100%;
  height: 100%;
  background: #333333;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.drawer-switch:checked ~ .drawer-overlay {
  opacity: 0.3;
  visibility: visible;
}

.drawer-content {
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: calc(100% - 64px);
  height: 100%;
  background: #30669B;
  transform: translateX(-100%);
  transition: 0.3s ease-in-out;
}

.drawer-switch:checked ~ .drawer-content {
  transform: translateX(0);
}

.drawer-content .drawer-menu {
  padding: 0 16px;
}

.drawer-content .drawer-menu__item:not(:nth-last-of-type(1)) {
  border-bottom: dashed 1px #ffffff;
}

.drawer-content .drawer-menu__item a {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  height: 56px;
  padding-right: 20px;
  color: #ffffff;
  text-decoration: none;
}

@media screen and (max-width: 520px) {
  .drawer-content .drawer-menu__item a {
    font-size: 14px;
  }
}

.drawer-content .drawer-menu__item a::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 8px;
  width: 10px;
  height: 10px;
  border-style: solid;
  border-width: 2px 2px 0 0;
  border-color: #ffffff;
  transform: translateY(-50%) rotateZ(45deg);
}
