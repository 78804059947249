// パーツの読み込み
@import '../common/_variable.scss'; // 変数設定の読み込み

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//
// ヘッダー（たまごカート用）
//
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  .footer {
    // リセットcss
    html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline;}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block;}body{line-height:1;}ol,ul{list-style:none;}blockquote,q{quotes:none;}blockquote::before,blockquote::after,q::before,q::after{content:'';content:none;}table{border-collapse:collapse;border-spacing:0;}input,select,button,textarea{margin:0;padding:0;background:transparent;border:none;border-radius:0;outline:none;appearance:none;}select::-ms-expand{display:none;}*,*::before,*::after{box-sizing:border-box;}
    // ベース調整
    box-sizing: border-box;
    line-height: 1.6;
    letter-spacing: 0.05em;
    a {color: $blk;text-decoration:none;overflow-wrap: break-word;transition:0.3s ease-out;&:visited{color:$blk;}&:hover{color:$blk;}&:active {color:$blk;}&[href*="tel:"]{text-decoration:none;pointer-events:none;cursor:default;}}
    img {display:block;max-width:100%;height:auto;vertical-align:bottom;&:not(.no-fit){width:100%;}}
    .list__item{margin-left:1em;text-indent:-1em;&:not(:nth-last-of-type(1)){margin-bottom:4px;}&::before {content:'・';}}
    @for $i from 1 through 20 {
      .mb-x#{$i} {
        margin-bottom: $space * $i * 0.75;
        @include break(xs) {
          margin-bottom: $space * $i * 0.5;
        }
      }
    }
  }
  .footer {
    position: sticky;
    top: 100vh;
    width: 100%;
    @include wrap(1280);
    padding: 40px 32px;
    background: rgba($beg, 0.2);
    @include break(xs) {
      padding: 20px 16px;
    }
    .footer-info {
      padding: 24px;
      background: $wht;
      border-radius: 2px;
      @include break(xs) {
        padding: 12px;
      }
      .footer-info-unit {
        @include flex();
        gap: 24px;
        &__col {
          width: 100%;
        }
      }
      .footer-info-sect:not(:nth-last-of-type(1)) {
        margin-bottom: 16px;
      }
      .footer-info-ttl {
        margin-bottom: 16px;
        padding-bottom: 8px;
        border-bottom: solid 3px $beg;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.4
      }
      .footer-info-subttl {
        position: relative;
        margin-bottom: 8px;
        font-weight: bold;
        &::before {
          content: '●';
        }
        span {
          font-size: 14px;
        }
      }
      .footer-info-link {
        a {
          color: $grn;
          font-size: 14px;
          font-weight: bold;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
      .footer-info-payment {
        max-width: 380px;
      }
      .footer-info-credit {
        max-width: 252px;
      }
    }//.footer-info
    .footer-nav {
      @include flex(center);
      margin-bottom: 24px;
      &__item {
        position: relative;
        padding: 0 8px;
        font-size: 12px;
        font-weight: bold;
        &:not(:nth-last-of-type(1))::after {
          content: '';
          @include pos($t:5px,$r:0);
          width: 1px;
          height: 12px;
          background: $blk;
        }
        a {
          &:hover {
            color: $grn;
            text-decoration: underline;
          }
        }
      }
    }//.footer-nav
    .copy {
      padding-bottom: 40px;
      font-size: 10px;
      text-align: center;
      @include break(xs) {
        padding-bottom: 48px;
      }
    }//.copy
  }//.footer

//--------------------------------------------------//
// 固定フッター
//--------------------------------------------------//
  .float-foot {
    box-sizing: border-box;
    @include pos($p:fixed,$b:0,$l:0,$z:999);
    @include flex();
    gap: 8px;
    width: 100%;
    height: 64px;
    padding: 0 8px;
    background: $wht;
    &__item {
      width: calc(50% - 32px);
      padding: 8px 0;
      a {
        @include flex(center,center);
        gap: 8px;
        height: 100%;
        background: $blu;
        border-radius: 2px;
        color: $wht;
        font-size: 14px;
        text-decoration: none;
        @include break(xs) {
          gap: 4px;
          font-size: 12px;
        }
        .ico-tel {
          width: 24px;
          height: 16px;
          fill: $wht;
          @include break(xs) {
            width: 20px;
            height: 14px;
          }
        }
      }
    }
  }


//--------------------------------------------------//
// ドロワーメニュー
//--------------------------------------------------//
.drawer {
  box-sizing: border-box;
  display: block;
  position: relative;
  top: 0;
  right: 0;
  z-index: 9999;
  .drawer-switch {
    display: none;
  }
  // ハンバーガー
  @at-root .drawer-hamburger {
    display: block;
    @include pos($p:fixed,$r:8px,$b:8px,$z:9998);
    width: 48px;
    height: 48px;
    background: $blu;
    border-radius: 2px;
    transition: 0.3s ease-out;
    cursor: pointer;
    span {
      display: block;
      position: relative;
      top: 50%;
      width: 16px;
      height: 2px;
      margin: 0 auto;
      transform: translateY(-50%);
      &::before,
      &::after {
        content: '';
        display: block;
        @include pos($l:0);
        width: 100%;
        height: 2px;
        background: $wht;
        transition: 0.3s ease-in-out;
      }
      &::before {
        top: -4px;
      }
      &::after {
        top: 4px;
      }
    }
    .drawer-switch:checked ~ & {
      span::before {
        top: 0;
        transform: rotate(45deg);
      }
      span::after {
        top: 0;
        transform: rotate(-45deg);
      }
    }
  }
  // オーバーレイ
  @at-root .drawer-overlay {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9997;
    width: 100%;
    height: 100%;
    background: $blk;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    .drawer-switch:checked ~ & {
      opacity: 0.3;
      visibility: visible;
    }
  }
  // ドロワーコンテンツ
  @at-root .drawer-content {
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: calc(100% - 64px);
    height: 100%;
    background: $blu;
    transform: translateX(-100%);
    transition: 0.3s ease-in-out;
    .drawer-switch:checked ~ & {
      transform: translateX(0);
    }
    .drawer-menu {
      padding: 0 16px;
      &__item {
        &:not(:nth-last-of-type(1)) {
          border-bottom: dashed 1px $wht;
        }
        a {
          @include flex('',center);
          position: relative;
          height: 56px;
          padding-right: 20px;
          color: $wht;
          text-decoration: none;
          @include break(xs) {
            font-size: 14px;
          }
          &::after {
            content: '';
            @include pos($t:50%,$r:8px);
            width: 10px;
            height: 10px;
            border-style: solid;
            border-width: 2px 2px 0 0;
            border-color: $wht;
            transform: translateY(-50%) rotateZ(45deg);
          }
        }
      }
    }
  }
}